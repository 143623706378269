import React from 'react'
import styled from 'styled-components'
import LogoImage from './images/tabreezi-logo'
import BackgroundImage from './images/background-stars'
import MainImage from './images/icon-campfire'

const text = {
  quote: 'NOTHING IS STRONGER THAN AN IDEA WHOSE TIME HAS COME. ',
  author: '  -VICTOR HUGO',
  headline: 'ALIGNING YOUR VISION',
  textboxOne:
    "GREAT PROJECTS BEGIN WITH A TRUE UNDERSTANDING \nOF YOUR CORE VISION. OUR JOB IS TO MAKE SURE THAT VISION DOESN'T GO UNNOTICED. \n\nAS ENTREPRENEURS WE KNOW THE MOST IMPORTANT WORK HAPPENS BEFORE WRITING A SINGLE LINE OF CODE. OUR FUNDAMENTAL GOAL IS TO UNDERSTAND YOUR WHY:",
  textboxTwo:
    '\n\nWHY ARE YOU PASSIONATE ABOUT THIS PROJECT? \nWHY ARE YOU ON THIS JOURNEY? \n\nONCE WE ARE ALIGNED WE DO EVERYTHING WE CAN TO BRING THE FULL REALIZATION OF YOUR IDEA TO LIFE.',
}

const Landing = () => {
  const { quote, textboxOne, textboxTwo, author } = text
  return (
    <PageContainer>
      <BackgroundImageContainer>
        <BackgroundImage />
      </BackgroundImageContainer>
      <ContentContainer>
        <ImageContainer>
          <LogoImage />
        </ImageContainer>
        <QuoteBox>
          <Quote>{quote}</Quote>
          <Author>{author}</Author>
        </QuoteBox>
        <MainImageContainer>
          <MainImage />
        </MainImageContainer>
        <VisionTextContainer>
          <TextContainer>
            {textboxOne}
            {textboxTwo}
          </TextContainer>
        </VisionTextContainer>
      </ContentContainer>
    </PageContainer>
  )
}

export default Landing

const VisionTextContainer = styled.div`
  padding-top: 3%;
  padding-bottom: 3%;
  max-width: 1200px;
  margin: 0px auto;
`
const MainImageContainer = styled.div`
  width: 100%;
`
const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: 0px auto;
`
const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: black;
  margin: 0px auto;
`

const PageContainer = styled.div`
  position: relative;
  text-align: center;
  background: black;
  color: white;
  padding-top: 30px;
  min-height: 100vh;

  @media screen and (min-width: 768px) {
    padding-top: 60px;
  }
`

const QuoteBox = styled.div`
  display: inline-block;
  padding-top: 20px;
  max-width: 1200px;
  margin: 0px auto;

  @media screen and (min-width: 375px) {
    padding-top: 4%;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 60px;
  }
`

const ImageContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  z-index: 100;
  max-width: 1200px;
  margin: 0px auto;
`
const Quote = styled.div`
  font-size: 10px;
  z-index: 100;
  display: inline;

  @media screen and (min-width: 375px) {
    font-size: calc(10px + (32 - 10) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`

const Author = styled.div`
  font-size: 8px;
  display: inline;

  @media screen and (min-width: 375px) {
    font-size: calc(8px + (16 - 8) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`

const TextContainer = styled.div`
  font-size: 12px;
  z-index: 1;
  margin: 0px auto;
  padding: 0px 30px 35px;
  max-width: 900px;
  line-height: 1.5;
  white-space: pre-line;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
    line-height: 1.666666;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 25px;
    font-weight: 100;
  }
`
