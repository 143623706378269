import React from 'react'
import Landing from '../components/landing'
import SEO from '../components/seo'

const IndexPage = () => (
  <>
    <SEO title="Tabreezi Home" />
    <Landing />
  </>
)

export default IndexPage
